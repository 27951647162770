<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            id="add-template"
            variant="primary"
            class="ml-50"
            @click="showAddProgram"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="text-nowrap ml-1">Add Template</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <template-list
      ref="templatesList"
      :show-deleted="showDeleted"
      @updateProgram="onUpdateProgram"
    />

    <add-edit-template
      ref="addProgram"
      :program="program"
      @programAdded="onProgramAdded"
    />
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard } from "bootstrap-vue";
import AddEditTemplate from "./AddEditTemplate.vue";
import TemplateList from "./TemplatesList.vue";
import { ProgramClass, programTypes } from "@/models";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    AddEditTemplate,
    TemplateList,
  },
  data() {
    return {
      showDeleted: false,
      program: null,
    };
  },
  methods: {
    showAddProgram() {
      this.program = new ProgramClass({
        type_id: programTypes.STANDARD
      });
      this.$refs.addProgram.show();
    },
    onUpdateProgram(program) {
      this.program = program;
      this.$refs.addProgram.show();
    },
    onProgramAdded() {
      this.$refs.templatesList.loadItems();
    },
  },
};
</script>

<style lang="scss" scoped>
tbody > tr > td,
tr > td {
  vertical-align: middle !important;
}

.badge {
  font-weight: 400 !important;
}
</style>
