<template>
  <section>
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status_id'">
          <b-badge :variant="STATUS_COLOR[props.row.status.name]">
            {{ props.row.status.name }}
          </b-badge>
        </span>
        <!-- Column: Name -->
        <span v-else-if="props.column.field === 'name'">
          <del
            v-if="props.row.deleted_at"
            style="color: red"
          >
            <router-link
              :to="{ name: 'admin-template', params: { id: props.row.id } }"
            >
              {{ props.row.name }}
            </router-link>
          </del>
          <p v-else>
            <router-link
              :to="{ name: 'admin-template', params: { id: props.row.id } }"
              class="table-primary-link"
            >
              {{ props.row.name }}
            </router-link>
          </p>
        </span>
        <!-- Column: Action -->
        <div
          v-else-if="props.column.field === 'action'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap vgt-center-align"
        >
          <div class="d-flex align-items-center mb-0 mt-0">
            <b-button
              variant="outline-none"
              class="nav pr-0"
            >
              <b-nav-item-dropdown class="btn-icon">
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />
                </template>

                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="showUpdateTemplate(props.row)"
                >
                  <feather-icon
                    size="16"
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
            <b-button
              variant="outline-none"
              class="btn-icon pr-0 pl-0"
              @click.prevent="deleteProgram(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="TrashIcon"
                class="mr-50"
              />
            </b-button>
          </div>
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="(value) => props.pageChanged({ currentPage: value })"
        />
      </template>
    </vue-good-table>
  </section>
</template>

<script>
import {
  BBadge,
  BButton,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import {
  STATUS_COLOR,
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
  localDateStringOrDateRangeStringToUtcRange,
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import programsService from "@/services/programsService";
import {
  programTypeDisplay,
  programStatus,
  programStatusSelect,
} from "@models/enums/ePrograms";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BBadge,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    TablePagination
  },
  props: {
    showDeleted: {
      type: Boolean,
    },
    clientId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      program: null,
      showArchived: true,
      isLoading: false,
      element: {},
      dir: false,
      columns: [
        {
          label: "Template Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Created By",
          field: "created_by.full_name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Updated By",
          field: "updated_by.full_name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Type",
          field: "type.name",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: programTypeDisplay.map(t => ({
              value: t.id,
              text: t.text,
            })),
          },
        },
        {
          label: "Last Updated",
          field: "updated_at",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
          tdClass: "search-date-selector",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          field: "action",
          width: "6em",
          sortable: false,
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
      currentProgram: null,
      programTypeDisplay,
      showDuplicateModal: false,
      duplicateProgramModel: {},
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  mounted() {
    const inputs = document.getElementsByName("vgt-updated_at");
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  created() {
    this.loadItems();
  },
  methods: {
    showUpdateTemplate(program) {
      this.$emit("updateProgram", program);
    },
    gridColumns() {
      const cols = [];
      cols.push({
        label: "Program Name",
        field: "name",
        filterOptions: {
          enabled: true,
          placeholder: "Search Name",
        },
      });
      cols.push(
        {
          label: "Type",
          field: "type.name",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: programTypeDisplay.map(t => ({
              value: t.id,
              text: t.text,
            })),
          },
        },
        {
          label: "Licence Start Date",
          field: "licence_start_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
            filterFn: this.dateFilter,
          },
          tdClass: "search-date-selector",
          width: "8em",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          label: "License End Date",
          field: "licence_end_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
            filterFn: this.dateFilter,
          },
          tdClass: "search-date-selector",
          width: "8em",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          label: "Total Matches",
          field: "matches_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
          width: "10em",
          tdClass: "text-center",
        },
        {
          label: "Matches Allowed",
          field: "match_limit",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
          width: "9em",
          tdClass: "text-center",
        },
        {
          label: "Active Participants",
          field: "active_users_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
          width: "9em",
          tdClass: "text-center",
        },
        {
          label: "Status",
          field: "status_id",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: programStatusSelect,
          },
          width: "8em",
        },
        {
          field: "action",
          width: "8em",
          sortable: false,
        }
      );
      return cols;
    },
    async suspendProgram(programId) {
      try {
        const response = await programsService.getProgramByProgramId(programId);

        this.currentProgram = response.data;

        if (this.currentProgram.header_code === null) {
          this.currentProgram.header_code = "--";
        }
        if (!this.currentProgram.footer_code) {
          this.currentProgram.footer_code = "--";
        }
        if (!this.currentProgram.email_signature) {
          this.currentProgram.email_signature = "--";
        }

        this.currentProgram.status_id = programStatus.SUSPENDED;
        await programsService.updateProgram(programId, this.currentProgram);
        this.loadItems();
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast());
      }
    },
    async setProgramActive(programId) {
      try {
        const response = await programsService.getProgramByProgramId(programId);

        this.currentProgram = response.data;

        if (this.currentProgram.header_code === null) {
          this.currentProgram.header_code = "--";
        }
        if (!this.currentProgram.footer_code) {
          this.currentProgram.footer_code = "--";
        }
        if (!this.currentProgram.email_signature) {
          this.currentProgram.email_signature = "--";
        }

        this.currentProgram.status_id = programStatus.ACTIVE;
        await programsService.updateProgram(programId, this.currentProgram);
        this.loadItems();
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast());
      }
    },
    async archiveProgram(programId) {
      try {
        const response = await programsService.getProgramByProgramId(programId);

        this.currentProgram = response.data;

        if (this.currentProgram.header_code === null) {
          this.currentProgram.header_code = "--";
        }
        if (!this.currentProgram.footer_code) {
          this.currentProgram.footer_code = "--";
        }
        if (!this.currentProgram.email_signature) {
          this.currentProgram.email_signature = "--";
        }

        this.currentProgram.status_id = programStatus.ARCHIVED;
        await programsService.updateProgram(programId, this.currentProgram);
        this.loadItems();
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast());
      }
    },
    async deleteProgram(programId) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this Template ?", {
          title: "Delete Template",
          size: "sm",
          okVariant: "primary",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              await programsService.deleteProgram(programId);
              this.$toast(makeSuccessToast("Template deleted successfully."));
              this.loadItems();
            } catch (e) {
              this.$log.error(e);
              this.$toast(
                makeErrorToast("Something went wrong. Template not deleted.")
              );
            }
          }
        });
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (field === "type.name") {
        field = "type_id";
      }
      if (type !== "none") {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "name",
        "created_by.full_name",
        "updated_by.full_name",
        "type.name",
        "updated_at",
      ]) {
        if (params.columnFilters[col]) {
          if (col === "type.name") {
            columnFilters.push({
              field: "type_id",
              value: params.columnFilters[col],
            });
          } else if (col === "updated_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(
              params.columnFilters[col]
            );
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    refreshList() {
      if (!this.isLoading) {
        this.loadItems();
      }
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await programsService.getListTemplates({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { total, items } = response.data;
        this.total = total;
        this.rows = items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    duplicateProgram(program) {
      this.showDuplicateModal = true;
      this.duplicateProgramModel = program;
    },
    closeDuplicateModal() {
      this.showDuplicateModal = false;
      this.duplicateProgramModel = {};
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      STATUS_COLOR,
      total,
      perPage,
      page,
    };
  },
};
</script>

<style>
.search-date-selector {
  text-align: center !important;
}
</style>

<style lang="scss" scoped>
tbody > tr > td {
  vertical-align: middle !important;
}

tr > td {
  vertical-align: middle !important;
}

.badge {
  font-weight: 400 !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
